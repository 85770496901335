import { mapGetters } from 'vuex';

export default {
  data: function() {
    return {
      navs: [
        {
          id: '01',
          name: '顧客情報一覧',
          path: '/event/user',
          routeName: 'EventUser',
          includes: false,
        },
        {
          id: '02',
          name: '顧客情報登録',
          path: '/event/user/register',
          routeName: 'EventUserRegister',
          includes: false,
        },
        {
          id: '03',
          name: 'テンプレート一覧',
          path: '/event/user/templates/list',
          routeName: 'EventUserTemplates',
          includes: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isClientChild: 'auth/isClientChild',
      useOmise: 'event/useOmise',
    }),
    pageNavs() {
      if (this.isClientChild) return [];
      if (!this.useOmise) return this.navs.slice(0, 2);
      return this.navs;
    }
  },
};
