<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>{{ titleText }}</template>
              <template v-slot:body>
                <div class="form">
                  <FormRow :required="true">
                    <template v-slot:label>テンプレート名</template>
                    <template v-slot:labelNote>（20文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.name }"
                          type="text"
                          name="name"
                          maxlength="20"
                          v-trim
                          v-maxlength
                          v-model="form.name"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true">
                    <template v-slot:label>クーポンタイトル</template>
                    <template v-slot:labelNote>（30文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.title }"
                          placeholder="【記入例】天然素材でのカラーリングを体験したい方必見！"
                          type="text"
                          name="title"
                          maxlength="30"
                          v-trim
                          v-maxlength
                          v-model="form.title"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true">
                    <template v-slot:label>特典内容</template>
                    <template v-slot:labelNote>（250文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <textarea
                          :class="{ 'form-control form-textarea': true, 'is-error': errors.content }"
                          placeholder="【記入例】1日5名様限定！ヘナカラーをお試し今だけ30％OFF!"
                          type="text"
                          name="content"
                          maxlength="250"
                          v-trim
                          v-maxlength
                          v-model="form.content"
                        ></textarea>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true">
                    <template v-slot:label>提示条件</template>
                    <template v-slot:labelNote>（15文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.preCondition }"
                          placeholder="【記入例】予約時/注文時"
                          type="text"
                          name="preCondition"
                          maxlength="15"
                          v-trim
                          v-maxlength
                          v-model="form.preCondition"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true">
                    <template v-slot:label>利用条件</template>
                    <template v-slot:labelNote>（250文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <textarea
                          :class="{ 'form-control form-textarea': true, 'is-error': errors.useCondition }"
                          placeholder="【記入例】他券サービス・併用不可
                 お一人様一回限りご利用可能"
                          type="text"
                          name="useCondition"
                          maxlength="250"
                          v-trim
                          v-maxlength
                          v-model="form.useCondition"
                        ></textarea>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true">
                    <template v-slot:label>クーポン画像</template>
                    <template v-slot:subLabel>
                      <span class="ml-20">画像添付（jpg/png形式・1ファイル3MBまで）</span>
                    </template>
                    <template v-slot:content>
                      <div class="form-content-row ml-50">
                        <ul class="fileUpload">
                          <li class="fileUpload-item">
                            <Fileupload
                              :maxSize="3"
                              @uploaded="uploadImage"
                              @removed="deleteImage"
                              :loading="isUploadLoading"
                              :initialUrl="initialImage"
                              :isError="errors.images"
                            />
                          </li>
                        </ul>
                      </div>
                      <div class="form-content-row ml-50">
                        <p>※推奨サイズ：750ｘ500px</p>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true">
                    <template v-slot:label>期限</template>
                    <template v-slot:labelNote>（クーポンが利用できる期間）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <ul class="listGroup">
                          <li v-for="(item, index) in templateUseDate" :key="index" class="listGroup-item">
                            <label class="form-radio d-inline-block">
                              <input
                                :class="{ 'form-radio-input': true, 'is-error': errors.expirationType }"
                                type="radio"
                                name="expirationType"
                                v-model="form.expirationType"
                                :value="item.value"
                              />
                              <span class="form-radio-label">{{ item.label }}</span>
                            </label>
                            <transition name="fade" v-if="item.value === limitType && form.expirationType === limitType">
                              <div class="form-content-row listGroup-item-child">
                                <DateRange
                                  type="dateTime"
                                  field="expirationDate"
                                  startField="startDate"
                                  endField="endDate"
                                  :isConfigNoMinutes="true"
                                  :value="form.expirationDate"
                                  :errorField="errors.expirationDate"
                                  @on-change="onChangeDateRange"
                                />
                              </div>
                            </transition>
                          </li>
                        </ul>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>プッシュ通知タイトル</template>
                    <template v-slot:labelNote>（40文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.pushMesssage }"
                          type="text"
                          name="pushMesssage"
                          maxlength="40"
                          v-trim
                          v-maxlength
                          v-model="form.pushMesssage"
                        />
                      </div>
                    </template>
                  </FormRow>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white" :to="{ name: 'EventUserTemplates' }">キャンセル</router-link>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-main" :handle-submit="handleSubmit" :button-text="buttonText" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
//mixins
import nav from '@/mixins/nav/user';
import error from '@/mixins/plugin/error';
//components
import FormRow from '@/components/FormRow.vue';
import Fileupload from '@/components/Fileupload.vue';
import DateRange from '@/components/DateRange.vue';
//constants
import { StatusConstants } from '@/constants/status';
//helpers
import { isDateWithSameAsValue } from '@/helpers/validators';

export default {
  data: function() {
    return {
      pageName: '顧客管理',
      storeModule: "customer",
      form: {
        name: '',
        title: '',
        content: '',
        preCondition: '',
        useCondition: '',
        images: null,
        expirationType: 0,
        expirationDate: {
          startDate: '',
          endDate: '',
        },
        pushMesssage: '',
      },
      prefixs: ['expirationDate'],
      initialImage: '',
      isOldImage: true,
      isNewImage: false,
      isUploadLoading: false,
      limitType: StatusConstants.templateUseDate.limit.value,
    };
  },
  mixins: [nav, error],
  components: {
    FormRow,
    Fileupload,
    DateRange,
  },
  validations() {
    const form = {
      name: { required, maxLength: maxLength(20) },
      title: { required, maxLength: maxLength(30) },
      content: { required, maxLength: maxLength(250) },
      preCondition: { required, maxLength: maxLength(15) },
      useCondition: { required, maxLength: maxLength(250) },
      images: { selectRequired: required },
      expirationType: { required },
      expirationDate: {},
      pushMesssage: { maxLength: maxLength(40) },
    };
    if (this.isLimitType) {
      if (!this.form.expirationDate?.startDate || !this.form.expirationDate?.endDate) {
        form.expirationDate = {
          startDate: { selectRequired: required },
          endDate: { selectRequired: required }
        }
      } else {
        form.expirationDate = {
          startDate: { selectRequired: required },
          endDate: {
            selectRequired: required,
            notRegistDateTime: isDateWithSameAsValue(this.form.expirationDate?.startDate, this.form.expirationDate?.endDate)
          }
        }
      }
    }
    return { form };
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
      templateDetail: 'customer/templateDetail',
    }),
    templateUseDate() {
      return Object.values(StatusConstants.templateUseDate);
    },
    subdomain() {
      return this.$route.params.subdomain;
    },
    templateId() {
      return this.$route.params.id;
    },
    isLimitType() {
      return this.form.expirationType === this.limitType;
    },
    buttonText() {
      return !this.templateId ? '登録する' : '編集する';
    },
    titleText() {
      return !this.templateId ? '通常クーポン登録' : '通常クーポン編集';
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    this.initForm();
    Promise.all([
      this.$store.dispatch('event/getParentEventDetail', this.subdomain),
      this.getDetail(),
    ]).finally(() => {
      if (this.templateId) {
        this.form = {
          ...this.form,
          ...this.templateDetail,
          images: this.templateDetail?.image.id,
          expirationDate: {
            startDate: this.templateDetail?.useStartDate,
            endDate: this.templateDetail?.useEndDate,
          }
        };
        this.initialImage = this.templateDetail?.image.url;
      }
      this.$loading.clear(loading);
    })
  },
  watch: {
    'form.expirationType'(val) {
      if (!val) {
        this.form.expirationDate.startDate = '';
        this.form.expirationDate.endDate = '';
      }
    }
  },
  methods: {
    initForm() {
      this.form = {
        name: '',
        title: '',
        content: '',
        preCondition: '',
        useCondition: '',
        images: null,
        expirationType: 0,
        expirationDate: {
          startDate: '',
          endDate: '',
        },
        pushMesssage: '',
      };
    },
    async uploadImage(url) {
      this.isUploadLoading = true;
      this.isNewImage = true;
      this.form.images = await this.$store.dispatch('common/uploadImage', {
        image: url,
        isTemplate: true,
      });
      this.isUploadLoading = false;
    },
    deleteImage() {
      this.form.images = '';
      this.initialImage = '';
      this.isOldImage = false;
    },
    onChangeDateRange(field, type, val) {
      this.form[field] = {
        ...this.form[field],
        [type]: val,
      }
    },
    async getDetail() {
      if (this.templateId) {
        await this.$store.dispatch('customer/getTemplateDetail', {
          subdomain: this.subdomain,
          id: this.templateId
        });
        this.form = {
          ...this.form,
          ...this.templateDetail,
          expirationDate: {
            ...this.form.expirationDate,
          }
        }
      }
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'template');
        return false;
      } else {
        let { expirationDate, ...payload } = this.form;
        const data = {
          ...payload,
          id: this.templateId,
          imageId: this.form.images,
          pushType: !!this.form.pushMesssage,
          useStartDate: this.isLimitType ? expirationDate.startDate : '',
          useEndDate: this.isLimitType ? expirationDate.endDate : '',
        }
        delete data.images;
        if (!this.templateId) delete data.id;
        const actionPath = !this.templateId ? 'createTemplate' : 'updateTemplate';
        const result = await this.$store.dispatch(`${this.storeModule}/${actionPath}`, data);
        if (result) {
          const actionName = this.templateId ? 'updated' : 'created';
          await this.$router.push({ name: 'EventUserTemplates' });
          this.$message[actionName]('template');
        }
      }
    }
  },
  beforeDestroy() {
    if (!this.$route.name.includes('EventUserTemplates')) sessionStorage.removeItem('tabActive')
  }
};
</script>
